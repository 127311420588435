import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteApi from "../rest/SiteApi";

function getNotificationType(action) {
    return (action.payload && action.payload?.success) ? 'success' : 'error';
}

const getConfigRequest = createAsyncThunk("client/config", async (payload) => {
    const result = await SiteApi.getConfigData(payload);
    return result.data;
});

const getClientDataRequest = createAsyncThunk("client/details", async (payload) => {
    const result = await SiteApi.getClientData(payload);
    return result.data;
});

const validateClientDataRequest = createAsyncThunk("client/validate", async (payload) => {
    const result = await SiteApi.validateClientData(payload);
    return result.data;
});

const getBanksRequest = createAsyncThunk("banks/list", async (payload) => {
    const result = await SiteApi.getBanks(payload);
    return result.data;
});

const generateAuthorizationRequest = createAsyncThunk("consent/authorize", async (payload) => {
    const result = await SiteApi.generateAuthorization(payload);
    return result.data;
});

const checkConsentStatusRequest = createAsyncThunk("consents/status", async (payload) => {
    const result = await SiteApi.checkConsentStatus(payload);
    return result.data;
});

const rejectConsentRequest = createAsyncThunk("client/reject", async (payload) => {
    const result = await SiteApi.rejectConsent(payload);
    return result.data;
});

const enableRecashRequest = createAsyncThunk("client/recash", async (payload) => {
    const result = await SiteApi.enableRecash(payload);
    return result.data;
});

const commonSlice = createSlice({
    name: "common",
    initialState: {
        config: null,
        user: null,
        banks: null,
        consent: null,
        notification: null,
        loading: false
    },
    reducers: {
        notify: (state, action) => {
            state.notification = action.payload;
        },
        notified: (state, action) => {
            state.notification = null;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getConfigRequest.fulfilled, (state, action) => {
            console.log("Client config response: ", action.payload);
            if(action.payload.success) {
                state.config = action.payload;
            }
        });
        builder.addCase(getClientDataRequest.fulfilled, (state, action) => {
            console.log("Client data response: ", action.payload);
            if(action.payload.success) {
                state.user = action.payload;
            }
        });
        builder.addCase(validateClientDataRequest.fulfilled, (state, action) => {
            console.log("Client validation response: ", action.payload);
        });
        builder.addCase(getBanksRequest.fulfilled, (state, action) => {
            console.log("Bank list response: ", action.payload);
            if(action.payload.success) {
                state.banks = action.payload;
            }
        });
        builder.addCase(generateAuthorizationRequest.fulfilled, (state, action) => {
            console.log("Authorization response: ", action.payload);
            if(action.payload.success) {
                state.consent = action.payload;
            }
        });
        builder.addCase(checkConsentStatusRequest.fulfilled, (state, action) => {
            console.log("Check consent status response: ", action.payload);
        });
    }
});

const commonReducer = commonSlice.reducer;
const commonActions = commonSlice.actions;

export {
    commonReducer, commonActions, getConfigRequest, getClientDataRequest, validateClientDataRequest, getBanksRequest, generateAuthorizationRequest, checkConsentStatusRequest, rejectConsentRequest, enableRecashRequest
}